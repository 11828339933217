<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" lg="12" md="12">
          <template>
            <s-crud title="Configuración de Costo"
              :config="configCrud"
              :filter="filter"
              add
              edit
              remove
              @save="save($event)"
              @rowSelected="rowSelected($event)"
              noSearch
              restore
              ref="crudConfiCoste"
              no-full
            >
              <template v-slot:filter>
                <v-container>
                  <v-row justify="center">
                     <v-col
                      lg="3"
                      md="3"
                      xl="3"
                      sm="3"
                      cols="6"
                    >
                      <!-- Anterior 1199 -->
                      <s-select-definition
                        v-model="filter.TypeShip"
                        :def="1342"
                        label="Nave"
                      >
                      </s-select-definition>
                    </v-col>

                    <v-col
												cols="12"
												lg="3"
												md="4"
											>
												<s-select-definition
													label="Zona"
													:def="1253"
													v-model="filter.TypeZonLocation"
												></s-select-definition>
										</v-col>

                  </v-row>
                </v-container>
              </template>

              <template slot-scope="props">
                <v-container>
                  <v-row >

                    <v-col
												cols="12"
												lg="3"
												md="4"
											>
												<s-select-definition
													label="Zona"
													:def="1253"
													v-model="props.item.TypeZonLocation"
												></s-select-definition>
										</v-col>

                    <v-col
                      lg="2"
                      md="4"
                      xl="3"
                      sm="3"
                      cols="6"
                    >
                      <!-- Anterior 1199 -->
                      <s-select-definition
                        v-model="props.item.TypeShip"
                        :def="1342"
                        label="Nave"
                      >
                      </s-select-definition>
                    </v-col>

                    <v-col  lg="3"
                      md="4"
                      xl="3"
                      sm="3"
                      cols="6">
                      <s-select-project
                        label="Proyecto"
                        v-model="props.item.PrjCode"
                      />
                    </v-col>

                    <v-col  cols="6" lg="2" sm="3" md="4">
                      <s-select-definition
                        v-model="props.item.TypeCultive"
                        id="DedValue"
                        :def="1173"
                        label="Cultivo"
                      >
                      </s-select-definition>
                    </v-col>
                    <v-col
                      lg="2"
                      md="4"
                      xl="3"
                      sm="3"
                      cols="6"
                    >
                      <!-- Anterior 1199 -->
                      <s-select-definition
                        v-model="props.item.TypeCoste"
                        :def="1229"
                        label="Costo"
                      >
                      </s-select-definition>
                    </v-col>

                    
                  </v-row>

                  <v-row style="margin-bottom: 10px;">
                    <v-col cols="6" lg="3" class="s-col-form" >
                      <s-select
                        label="Centro de Costos"
                        item-value="CceID"
                        item-text="CceName"
                        :items="permit.CceList"
                        v-model="item.CceObject"
                        autocomplete
                        return-object 
                        
                        
                      />
                    </v-col>
                    <!-- v-model="item.CceObject" item.CceObject permit.CceList-->
                    <v-col cols="6" lg="3" class="s-col-form" >
                      <s-select
                        label="Linea De Negocio"
                        item-value="BslID"
                        item-text="BslName"
                        return-object
                        v-model="item.BslObject"
                        :items="permit.BslList"
                      />
                    </v-col>

                    <v-col cols="6" lg="3" class="s-col-form">
                      <s-select
                        item-value="ConName"
                        clearable
                        item-text="ConName"
                        label="Concepto Servicio"
                        v-model="ConName"
                        :items="itemsConcept"
                      />
                    </v-col>
                    <v-col cols="6" lg="3"  class="s-col-form">
                      <s-select
                        label="Cuenta Contable"
                        clearable
                        item-value="CsaAccount"
                        item-text="CsaDescription"
                        v-model="AcoID"
                        :items="itemsAccount"
                      />
                    </v-col>
                  </v-row>
                  
                </v-container>
              </template>
              <template v-slot:SecStatus="{ row }">
                <v-chip
                  x-small
                  :color="row.SecStatus == 1 ? 'success' : 'warning'"
                >
                  {{ row.SecStatus == 1 ? "Activo" : "Inactivo" }}
                </v-chip>
              </template>
            </s-crud>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
//Service
import _sRequirementPermit from "@/services/Logistics/PermitService";
import _sRequirement from "@/services/Logistics/RequirementService";
import _sCosteService from "@/services/Technicalassistance/TransportCosteService";

export default {
  components: {},
  data: () => ({
    itemsConcept: [],
    itemsAccount: [],
    permit: [],

    ConName: null,
    AcoID: null,

    filter: {},
    item: {
      BslName: "",
      CceName: "",
      BslObject: 0,
      CceObject: 0,
    },
  }),

  computed: {
    configCrud() {
      return {
        service: _sCosteService,
        model: {
          Tcc: "ID",
          SecStatus: "int",
        },
        headers: [
          { text: "Nave", value: "TypeShipName", width: 100 },
          { text: "Zona", value: "TypeZonLocationName", width: 100 },
          { text: "Cultivo", value: "TypeCultiveName", width: 20 },
          { text: "Costo", value: "TypeCosteName", width: 20 },
          { text: "Centro de Costos", value: "CceName", width: 20 },
          { text: "Proyecto", value: "PrjName", width: 20 },
          { text: "Linea Negocio", value: "BslName", width: 20 },
          { text: "Concepto Servicio", value: "CsaAccountConcept", width: 20 },
          { text: "Cuenta Contable", value: "CsaAccountName", width: 20 },
          { text: "Estado", value: "SecStatus", width: 20 },
        ],
      };
    },
  },
  created() {
    //Centro de Costos
    _sRequirementPermit
      .byUser({ UsrID: this.$fun.getUserID() }, this.$fun.getUserID())
      .then((r) => {
        this.permit = r.data;

        console.log("this.permit", this.permit);

        //Llena el Combo Centro de Costos
        this.permit.CceList = [
          ...new Map(this.permit.map((item) => [item["CceID"], item])).values(),
        ];
        if (this.permit.CceList.length > 0)
            this.item.CceObject = this.permit.CceList[0];
      });

    //Llena el Combo Concepto de Servicio
    _sRequirement.ConceptService(this.$fun.getUserID()).then((r) => {
      this.itemsConcept = r.data;
    });
  },

  watch: {
    "item.CceObject"() {
      
      this.permit.BslFull = this.permit.filter(
        (x) => x.CceID == this.item.CceObject.CceID
      );
      this.permit.BslList = [
        ...new Map(
          this.permit.BslFull.map((item) => [item["BslID"], item])
        ).values(),
      ];
      if (this.permit.BslList.length > 0)
        this.item.BslObject = this.permit.BslList[0];
    },
    
    ConName() {
      //Llenar el Combo Cuenta Contable
      if (this.ConName != null)
        _sRequirement
          .AccountConceptService(this.ConName, this.$fun.getUserID())
          .then((ar) => {
            this.itemsAccount = ar.data;
            console.log(this.AcoID);
          });
      else {
        this.itemsAccount = [];
      }
    }, 
  },

  methods: {

    rowSelected(val) {
      if (val.length != 0) {

          this.item.CceObject = this.permit.filter((x) => x.CceID == val[0].CceID)[0];
          this.item.BslObject = this.permit.filter((x) => x.BslID == val[0].BslID)[0];
          this.ConName = val[0].CsaAccountConcept;
          this.AcoID = val[0].CsaAccount;
      }
    },

    isValidated(){
      let isValidated = true;
      if (this.item.CceObject == null || this.item.CceObject == 0) {
        this.$fun.alert("Seleccione Centro de Costo", "warning");
        isValidated = false;
        return;
      }
      if (this.item.BslObject == null || this.item.BslObject == 0) {
        this.$fun.alert("Seleccione Linea de Negocio", "warning");
        isValidated = false;
        return;
      }

      if (this.AcoID == null) {
        this.$fun.alert("Seleccione cuenta Contable", "warning");
        isValidated = false;
        return;
      }

      return isValidated;
    },

    //Guardar
    save(val) {
      if(!this.isValidated()) { return; }

      if (val.TypeShip == null) {
        this.$fun.alert("Seleccione Nave", "warning");
        return;
      }

      if (val.TypeZonLocation == null) {
        this.$fun.alert("Seleccione Zona", "warning");
        return;
      }

      if (val.TypeCultive == null) {
        this.$fun.alert("Seleccione Cultivo", "warning");
        return;
      }
      
      val.BslID = this.item.BslObject.BslID;
      val.CceID = this.item.CceObject.CceID;
      val.CsaAccount = this.AcoID;
      val.SecStatus = 1;
      val.UsrCreateID = this.$fun.getUserID();

      val.save();
      console.log("Guardar", val);
      
    }, 

  },
};
</script>
